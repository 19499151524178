@import url(https://fonts.bunny.net/css?family=roboto);

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-normal text-base bg-light-darker-bg dark:bg-dark-darker-bg dark:bg-dark-darker-bg text-light-gray-text dark:text-dark-gray-text relative;
  }
}

@layer components {}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

/* hide input number arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* libraries */
.uppy-Container .uppy-DragDrop--isDragDropSupported {
  @apply border-white/5 border bg-light-dark-bg-active dark:bg-dark-dark-bg-active text-light-gray-text dark:text-dark-gray-text;
}

.uppy-Container .uppy-DragDrop-browse {
  @apply text-primary;
}

.react-light-select-container .react-light-select__control {
  @apply !bg-light-dark-bg-active !border-white/5 !text-light-gray-text !rounded-md !px-2 py-0.5;
}

.react-light-select-container .react-light-select__control--is-focused {
  @apply !border-primary !ring-1 !ring-primary;
}

.react-light-select-container .react-light-select__input {
  @apply !ring-0 !text-light-gray-text;
}

.react-light-select-container .react-light-select__multi-value {
  @apply !bg-primary/10 !text-primary !rounded-md !px-1;
}

.react-light-select-container .react-light-select__multi-value .react-light-select__multi-value__label {
  @apply !text-light-gray-text;
}

.react-light-select-container .react-light-select__multi-value .react-light-select__multi-value__remove svg {
  @apply !fill-white;
}

.react-light-select-container .react-light-select__multi-value .react-light-select__multi-value__remove:hover {
  @apply !bg-transparent opacity-50;
}

.react-light-select-container .react-light-select__menu {
  @apply !bg-light-darker-bg !border-2 !border-white/5 !rounded-md;
}

.react-light-select-container .react-light-select__menu .react-light-select__option {
  @apply !text-light-gray-text !bg-transparent;
}

.react-light-select-container .react-light-select__menu .react-light-select__option--is-focused {
  @apply !bg-primary/10 !text-primary;
}

.react-light-select-container .react-light-select__indicator-separator {
  @apply hidden;
}

.react-light-select-container .react-light-select__indicator {
  @apply !text-light-gray-text;
}

.react-light-select-container .react-light-select__single-value {
  @apply !text-light-gray-text;
}

.react-light-select-container.react-light-select-stats-period .react-light-select__control {
  @apply !bg-light-dark-bg !px-2 py-0 !text-sm;
}

.react-light-select-container.react-light-select-stats-period .react-light-select__indicator svg {
  @apply size-4;
}

.react-light-select-container.react-light-select-stats-period .react-light-select__menu {
  @apply z-[20];
}

.react-light-select-container.react-light-select-stats-period .react-light-select__option {
  @apply !text-sm;
}


.react-dark-select-container .react-dark-select__control {
  @apply !bg-dark-dark-bg-active !border-white/5 !text-dark-gray-text !rounded-md !px-2 py-0.5;
}

.react-dark-select-container .react-dark-select__control--is-focused {
  @apply !border-primary !ring-1 !ring-primary;
}

.react-dark-select-container .react-dark-select__input {
  @apply !ring-0 !text-dark-gray-text;
}

.react-dark-select-container .react-dark-select__multi-value {
  @apply !bg-primary/10 !text-primary !rounded-md !px-1;
}

.react-dark-select-container .react-dark-select__multi-value .react-dark-select__multi-value__label {
  @apply !text-dark-gray-text;
}

.react-dark-select-container .react-dark-select__multi-value .react-dark-select__multi-value__remove svg {
  @apply !fill-white;
}

.react-dark-select-container .react-dark-select__multi-value .react-dark-select__multi-value__remove:hover {
  @apply !bg-transparent opacity-50;
}

.react-dark-select-container .react-dark-select__menu {
  @apply !bg-dark-darker-bg !border-2 !border-white/5 !rounded-md;
}

.react-dark-select-container .react-dark-select__menu .react-dark-select__option {
  @apply !text-dark-gray-text !bg-transparent;
}

.react-dark-select-container .react-dark-select__menu .react-dark-select__option--is-focused {
  @apply !bg-primary/10 !text-primary;
}

.react-dark-select-container .react-dark-select__indicator-separator {
  @apply hidden;
}

.react-dark-select-container .react-dark-select__indicator {
  @apply !text-dark-gray-text;
}

.react-dark-select-container .react-dark-select__single-value {
  @apply !text-dark-gray-text
}

.react-dark-select-container.react-dark-select-stats-period .react-dark-select__control {
  @apply !bg-dark-dark-bg !px-2 py-0 !text-sm;
}

.react-dark-select-container.react-dark-select-stats-period .react-dark-select__indicator svg {
  @apply size-4;
}

.react-dark-select-container.react-dark-select-stats-period .react-dark-select__menu {
  @apply z-[20];
}

.react-dark-select-container.react-dark-select-stats-period .react-dark-select__option {
  @apply !text-sm;
}

input[type="file"]::file-selector-button {
  @apply !bg-light-dark-bg dark:bg-dark-dark-bg !text-light-gray-text dark:text-dark-gray-text !border-light-dark-bg dark:border-dark-dark-bg;
  border: 2px solid;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  transition: 0.1s;
}

input[type="file"]::file-selector-button:hover {
  @apply !bg-light-dark-bg dark:bg-dark-dark-bg !text-light-gray-text dark:text-dark-gray-text !border-primary;
  border: 2px solid;
  border-radius: 0.2em;
}